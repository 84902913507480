import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {FormatPipe} from "ngx-dayjs9";
import {ItemsListComponent} from "shared";
import {MqEvent, NavService} from "core";
import {DocumentsFilter} from "./documents-filter";
import {Document, DocumentArchiveBoxStatuses, DocumentBox, DocumentPipelineStatus, DocumentRegistryStatuses, DocumentSourceType, DocumentUnclassifiedBoxStatuses, DocumentViewMode, DocumentWorkingBoxStatuses} from "./documents.model";
import {DocumentType} from "workspace-taxonomy/workspace-taxonomy.model";
import {DynamicField, DynamicFieldValue, FieldDataType} from "dynamic-forms/dynamic-forms.model";
import {DynamicFormsService} from "dynamic-forms/dynamic-forms.service";
import {KtdGridLayout} from "@katoid/angular-grid-layout";
import {KtdGridLayoutItem} from "@katoid/angular-grid-layout/lib/grid.definitions";
import {concatMap, from, Observable, of} from "rxjs";
import {finalize} from "rxjs/operators";
import {AbstractControl, FormArray} from "@angular/forms";
import {JSONContent, generateHTML} from "@tiptap/core";
import {stripHtml} from "string-strip-html";
import {TiptapService} from "report-designer/tiptap.service";

@Injectable({providedIn: "root"})
export class DocumentsUtilService {
  constructor(private translateService: TranslateService,
              private dynamicFormsService: DynamicFormsService,
              private tiptapService: TiptapService) {
  }

  getDocumentBox(document: Document): DocumentBox {
    if (DocumentRegistryStatuses.indexOf(document.pipelineStatus) !== -1 && document.workspaceId) {
      return DocumentBox.Unclassified;
    }
    if (DocumentUnclassifiedBoxStatuses.indexOf(document.pipelineStatus) !== -1) {
      return DocumentBox.Unclassified;
    }
    if (DocumentWorkingBoxStatuses.indexOf(document.pipelineStatus) !== -1) {
      return DocumentBox.Working;
    }
    if (DocumentArchiveBoxStatuses.indexOf(document.pipelineStatus) !== -1) {
      return DocumentBox.Archive;
    }
    return DocumentBox.Registry;
  }

  getDocumentRouterLink(document: Document): string {
    const boxPath = this.getDocumentBoxPath(document);
    return `${boxPath[boxPath.length - 1].route}/${document.id}`;
  }

  computeOptimalViewMode(document: Document): DocumentViewMode {
    if (document.source === DocumentSourceType.Manual) {
      return DocumentViewMode.Pdf;
    }

    if (!document.hasPdf) {
      if (document.hasText) {
        return DocumentViewMode.Txt;
      }
      return DocumentViewMode.Events;
    }

    return DocumentViewMode.Pdf;
  }

  getPipelineStatusColor(pipelineStatus: DocumentPipelineStatus) {
    if (pipelineStatus === DocumentPipelineStatus.UploadError) {
      return "danger";
    }
    if (pipelineStatus === DocumentPipelineStatus.Imported) {
      return "info";
    }
    if (pipelineStatus === DocumentPipelineStatus.PdfCreationFailed) {
      return "danger";
    }
    if (pipelineStatus === DocumentPipelineStatus.Transferable) {
      return "success";
    }
    if (pipelineStatus === DocumentPipelineStatus.Unclassified) {
      return "warning";
    }
    if (pipelineStatus === DocumentPipelineStatus.Classified) {
      return "success";
    }
    if (pipelineStatus === DocumentPipelineStatus.Rejected) {
      return "warning";
    }
    if (pipelineStatus === DocumentPipelineStatus.Archived) {
      return "success";
    }
    if (pipelineStatus === DocumentPipelineStatus.Deleted) {
      return "danger";
    }

    return "dark";
  }

  validFor(documents: Array<Document>, allowOperation: string): boolean {
    return documents.every(d => d.allowedActions[allowOperation]);
  }

  validForPdfJoin(documents: Array<Document>): boolean {
    return documents?.length > 1 &&
      documents.every(d => d.pipelineStatus >= DocumentPipelineStatus.Transferable && d.pipelineStatus <= DocumentPipelineStatus.Unclassified);
  }

  handleDocumentUpdatedEvent(evt: MqEvent<Document>, currentBox: DocumentBox, listComponent: ItemsListComponent<Document, DocumentsFilter>) {
    const documentBox = this.getDocumentBox(evt.payload);
    if (currentBox === documentBox) {
      if (listComponent.handleOnItemUpdate(evt)) {
        listComponent.enableItem(evt.payload);
      }
    } else {
      listComponent.handleOnItemDelete(Object.assign({}, evt, {fromSocket: true}));
    }
  }

  getDocumentBoxPath(document: Document, includeWorkspacesRoot = true): Array<{title: string, route: string}> {
    const stringBox = DocumentBox[document.box].toLowerCase();
    return document.box === DocumentBox.Registry
      ? [{
        title: "registry.title",
        route: "/registry"
      }]
      : [{
        title: document.workspace?.name,
        route: `/workspaces/${document.workspace?.id}`
      }, {
        title: this.translateService.instant(`workspace.${stringBox}`),
        route: `/workspaces/${document.workspace?.id}/${stringBox}`
      }].filter(x => x);
  }

  setUpBreadcrumbs(document: Document, nav: NavService, modalController: ModalController) {
    return this.getDocumentBoxPath(document)
      .map(x => {
        return {
          title: x.title,
          handler: () => nav.go(x.route).then(() => modalController && modalController.dismiss())
        };
      });
  }

  getDocumentMetaData(document: Document): Array<DynamicFieldValue> {
    return [
      {fieldId: "document.meta.id", value: document?.id, fieldType: FieldDataType.String},
      {fieldId: "document.meta.creationTime", value: document?.creationTime, fieldType: FieldDataType.Date},
      {fieldId: "document.meta.changeTime", value: document?.changeTime, fieldType: FieldDataType.Date},
      {fieldId: "document.meta.size", value: document?.size, fieldType: FieldDataType.Numeric},
      {fieldId: "document.meta.name", value: document?.name, fieldType: FieldDataType.String},
      {fieldId: "document.meta.issueDate", value: document?.issueDate, fieldType: FieldDataType.Date},
      {fieldId: "document.meta.pageCount", value: document?.pageCount, fieldType: FieldDataType.Numeric},
      {fieldId: "document.meta.thumbUrl", value: document?.thumbUrl, fieldType: FieldDataType.String},
      {fieldId: "document.meta.pdfUrl", value: document?.pdfUrl, fieldType: FieldDataType.String},
      {fieldId: "document.meta.documentType.id", value: document?.documentType?.id, fieldType: FieldDataType.String},
      {fieldId: "document.meta.documentType.name", value: document?.documentType?.name, fieldType: FieldDataType.String},
      {fieldId: "document.meta.ownerUser.id", value: document?.ownerUser?.id, fieldType: FieldDataType.String},
      {fieldId: "document.meta.ownerUser.email", value: document?.ownerUser?.email, fieldType: FieldDataType.String},
      {fieldId: "document.meta.ownerUser.userName", value: document?.ownerUser?.userName, fieldType: FieldDataType.String},
      {fieldId: "document.meta.ownerUser.phoneNumber", value: document?.ownerUser?.phoneNumber, fieldType: FieldDataType.String},
      {fieldId: "document.meta.ownerUser.fullName", value: document?.ownerUser?.fullName, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.id", value: document?.workspace?.id, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.photoUrl", value: document?.workspace?.photoUrl, fieldType: FieldDataType.ImageUrl},
      {fieldId: "document.meta.workspace.name", value: document?.workspace?.name, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.cui", value: document?.workspace?.cui, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.vatId", value: document?.workspace?.cui, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.j", value: document?.workspace?.j, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.jointStock", value: document?.workspace?.jointStock, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.caen", value: document?.workspace?.caen, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.bank", value: document?.workspace?.bank, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.swift", value: document?.workspace?.swift, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.iban", value: document?.workspace?.iban, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.country", value: document?.workspace?.address?.country, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.region", value: document?.workspace?.address?.region, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.city", value: document?.workspace?.address?.city, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.zipCode", value: document?.workspace?.address?.zipCode, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.address", value: document?.workspace?.address?.line1, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.phoneNumber", value: document?.workspace?.address?.phoneNumber, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.email", value: document?.workspace?.address?.email, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.web", value: document?.workspace?.address?.web, fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.vatExemptId", value: "", fieldType: FieldDataType.String},
      {fieldId: "document.meta.workspace.vatExemptReason", value: "", fieldType: FieldDataType.String},
    ];
  }

  collectDocumentFields(documentType: DocumentType): Array<DynamicField> {
    const dateFields = [
      "document.meta.creationTime",
      "document.meta.changeTime",
      "document.meta.issueDate"
    ];

    return this.dynamicFormsService.denormalizeFields([]
      .concat(documentType.headerFields, documentType.detailFields, documentType.footerFields)
      .concat(this.getDocumentMetaData(null)
        .map(x => {
          return {
            fieldId: x.fieldId,
            fieldName: this.translateService.instant(x.fieldId),
            fieldType: x.fieldType
          } as DynamicField;
        })));
  }

  getKtdGridLayout(fields: Array<DynamicField>, cols: number): KtdGridLayout {
    return fields.map((f: DynamicField, idx: number) => {
      return {
        id: f.fieldId,
        x: f.layoutX || 0,
        y: (f.layoutY === undefined || f.layoutY === null) ? idx : f.layoutY,
        w: f.layoutWidth || cols,
        h: f.layoutHeight || 1
      } as KtdGridLayoutItem;
    });
  }

  purifyFormula(fields: Array<DynamicField>, formula: JSONContent) {
    if (!formula) {
      return "";
    }
    const denormalizedFields = this.dynamicFormsService.denormalizeFields(fields);
    const formulaHtml = generateHTML(formula, this.tiptapService.getFormulaEditorExtensions());
    const htmlRes = denormalizedFields.reduce((html: string, x: DynamicField) =>
        html
          .replace(new RegExp("<app-tiptap-node-field", "g"), "<span")
          .replace(new RegExp("</app-tiptap-node-field>", "g"), "</span>")
          .replace(new RegExp("<div", "g"), "<span")
          .replace(new RegExp("</div>", "g"), "</span>")
          .replace(new RegExp(`<span field-id=\"${x.fieldId}\"`, "g"), `[${x.fieldId}]<span`)
          .replace(new RegExp(`<span fieldid=\"${x.fieldId}\"`, "g"), `[${x.fieldId}]<span`)
          .replace(new RegExp(`${x.fieldName}</span>`, "g"), "</span>"),
      formulaHtml);
    return stripHtml(htmlRes, {stripTogetherWithTheirContents: ["span"]}).result;
  }

  formulaToExpression(fields: Array<DynamicField>, formula: JSONContent, rowData: Array<DynamicFieldValue> = null): string {
    const pureFormula = this.purifyFormula(fields, formula);
    if (!pureFormula) {
      return "";
    }
    const expression = fields
      .reduce((e: string, x: DynamicField) => {
        const regEx = new RegExp(`\\[${x.fieldId}]`, "g");
        const fieldValue = rowData.find(v => v.fieldId === x.fieldId);
        const replace = fieldValue?.value || (x.fieldType === FieldDataType.Numeric ? "0" : "");
        return e.replace(regEx, replace);
      }, pureFormula);
    return expression
      .replace(new RegExp("’", "g"), "'")
      .replace(new RegExp("‘", "g"), "'");
  }

  evaluateFormula(fields: Array<DynamicField>, formula: JSONContent, rowData: Array<DynamicFieldValue>, decimals: number = 2): number {
    const expression = this.formulaToExpression(fields, formula, rowData);
    if (!expression) {
      return null;
    }
    try {
      return Function(`"use strict";return +((${expression}).toFixed(${decimals}))`)();
    } catch {
      return null;
    }
  }

  evaluateFieldFormula(field: DynamicField, fields: Array<DynamicField>, rowData: Array<DynamicFieldValue>): any {
    const formulaValue = this.evaluateFormula(fields, field.formula, rowData, field.numericDecimals || 2);
    return formulaValue === null
      ? null
      : field.fieldType === FieldDataType.Numeric
        ? +(formulaValue || null)
        : [FieldDataType.String, FieldDataType.ImageUrl, FieldDataType.LongString, FieldDataType.SingleOption].includes(field.fieldType)
          ? formulaValue || ""
          : field.fieldType === FieldDataType.Boolean
            ? !!formulaValue
            : [FieldDataType.MultiOption, FieldDataType.StringList].includes(field.fieldType)
              ? [formulaValue || ""]
              : field.fieldType === FieldDataType.Date
                ? new FormatPipe().transform(Date.now(), "YYYY-MM-DD")
                : null;
  }

  refreshMath(field: DynamicField, fields: Array<DynamicField>, formArray: FormArray): Observable<Array<DynamicField>> {
    const affectedFields = fields.filter(x => x.fieldId !== field.fieldId && JSON.stringify(x.formula)?.includes(`"fieldId":"${field.fieldId}"`));
    if (!affectedFields?.length) {
      return of([]);
    }

    const changedFields: Array<DynamicField> = [];
    return new Observable(o => {
      console.log(`refreshMath: ${field.fieldId}, affected:`, affectedFields);
      from(affectedFields)
        .pipe(concatMap((f: DynamicField) => new Observable(s => {
          const val = this.evaluateFieldFormula(f, fields, formArray.value);
          if (val !== null) {
            const control: AbstractControl = formArray.controls.find(x => x.value.fieldId === f.fieldId);
            if (val !== +((control.value as DynamicFieldValue).value)) {
              control.setValue({
                fieldId: f.fieldId,
                value: val,
                error: null
              });
              console.log(`computed ${f.fieldId} = ${val}`);
              changedFields.push(f);
            }
          }
          s.next();
          s.complete();
        })))
        .pipe(finalize(() => {
          console.log(`refreshMath ${field.fieldId} finished, changed:`, changedFields);
          from(changedFields)
            .pipe(concatMap((f: DynamicField) => this.refreshMath(f, fields, formArray)))
            .pipe(finalize(() => {
              o.next(changedFields);
              o.complete();
            }))
            .subscribe();
        }))
        .subscribe();
    });
  }

  checkMath(field: DynamicField, fields: Array<DynamicField>, formArray: FormArray): boolean {
    if (!field.formula) {
      return true;
    }
    const val = this.evaluateFieldFormula(field, fields, formArray.value);
    if (val === null) {
      return true;
    }
    const control: AbstractControl = formArray.controls.find(x => x.value.fieldId === field.fieldId);
    const error = control.value.value.toString() === val.toString()
      ? null
      : `Wrong value. Should be '${val}'`;
    const currentValue: DynamicFieldValue = control.value;
    if (currentValue.error !== error) {
      control.setValue(Object.assign({}, control.value, {error}));
    }
    return currentValue.value === val;
  }
}
