@if (loadState.isWorking) {
  <app-preloader class="overlay-absolute"></app-preloader>
}
<ng2-pdfjs-viewer #pdfViewer
                  class="d-block w-100 h-100"
                  viewerId="MyID"
                  [pdfSrc]="pdfSrc$ | async"
                  [downloadFileName]="_downloadFileName"
                  [fullScreen]="false"
                  [openFile]="false"
                  [viewBookmark]="false"
                  [showSpinner]="false"
                  [print]="false"
                  [download]="false"
                  [find]="false"
                  [zoom]="'100'"
                  [locale]="locale"
                  (onDocumentLoad)="onPdfLoaded($event)"
                  (onPageChange)="onPdfPageChange($event)">
</ng2-pdfjs-viewer>
@if (loadState.isOk) {
  <div class="d-none d-md-block position-absolute bottom-0 h-center opacity-09">
    <app-action-bar [actions]="pdfToolbarActions"></app-action-bar>
  </div>
}
