import {Component, Input, Output, EventEmitter} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {ApiService, LoadState} from "core";

@Component({
  selector: "app-auth-img",
  templateUrl: "./auth-img.component.html",
  styleUrls: ["./auth-img.component.scss"]
})
export class AuthImgComponent {
  @Input() set url(value: string) {
    this.computePhotoSrc(value);
  }

  @Input() animate = true;
  @Input() cssStyle: string;
  @Output() loaded = new EventEmitter<any>();
  @Output() error = new EventEmitter<any>();

  loadState = new LoadState();
  photoSrc: string;
  isFinishedDrawing = false;

  constructor(private apiService: ApiService) {
  }

  computePhotoSrc(url: string) {
    this.loadState.setInit();
    if (!url) {
      this.imageError("No image");
      return;
    }
    if (["~/", "assets/", "data:image/", "capacitor://", "http://", "https://"].find(x => url.startsWith(x))) {
      this.photoSrc = url.replace(new RegExp("~/", "g"), "");
      if (url.includes("<svg")) {
        this.photoSrc = this.photoSrc.replace(new RegExp("#", "g"), "%23");
      }
      this.loadState.setOk();
      this.finishDrawing();
      return;
    }

    this.loadState.setInit();
    this.apiService.getAuthImageUrl(url)
      .subscribe((imageUrl: string) => {
        this.photoSrc = imageUrl;
        this.loadState.setOk();
        this.finishDrawing();
      }, (error: HttpErrorResponse) => {
        this.imageError(error);
      });
  }


  imageLoaded() {
    this.isFinishedDrawing = true;
    this.loaded.emit();
  }

  imageError(error) {
    this.loadState.setError(error);
    this.finishDrawing();
    this.error.emit(error);
  }

  finishDrawing() {
    setTimeout(() => this.isFinishedDrawing = true, 300);
  }
}
